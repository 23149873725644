import React from 'react';
import classes from './WhyIntro.module.css';
import {ReactComponent as DoorSvg} from '../../../assets/svg/doortodoor.svg';
import {ReactComponent as MonitoringSvg} from '../../../assets/svg/monitoring.svg';
import {ReactComponent as TimeSvg} from '../../../assets/svg/timecritical.svg';
import LanguageContext from '../../../context/language-context';

const WhyIntro = () => {
    return (
        
        <LanguageContext.Consumer>
            {context => (

                <div className={classes.WhyIntro}>
                    <h3>{context.text.why.intro.headline}</h3>
                    <div className={classes.WhyServices}>
                            <div className={classes.WhyServicesItem}>
                                <MonitoringSvg style={{width:'50%'}} />
                                <p>{context.text.why.intro.realtime}</p>
                            </div>
                            <div className={classes.WhyServicesItem}>
                                <DoorSvg style={{width:'50%'}} />
                                <p style={{paddingLeft: '5px'}}>{context.text.why.intro.doortodoor}</p>
                            </div>
                            <div className={classes.WhyServicesItem}>
                                <TimeSvg style={{width:'50%'}} />
                                <p>{context.text.why.intro.timecritical}</p>
                            </div>                       
                        
                    </div>
                </div>
            )}
        </LanguageContext.Consumer>
    )
}

export default WhyIntro
