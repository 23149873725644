import React from 'react';
import classes from './Intro.module.css';
import IntroServices from './IntroServices/IntroServices';
import LanguageContext from '../../../context/language-context';

const Intro = () => {
    return (
        <LanguageContext.Consumer>
            {context => (
                <section className={classes.HomeIntro}>
                    <div className={context.isIE ? classes.TitleIE : classes.Title}>
                        <h2>Pagoda Logistics</h2>
                    </div>
                    <div className={context.isIE ? classes.ArticleContainerIE : classes.ArticleContainer}>
                        <article>
                            {context.text.home.intro.article}
                        </article>

                    </div>
                    <div className={classes.IntroServicesContainer}>
                        <IntroServices />
                    </div>

                </section>

            )}

        </LanguageContext.Consumer>
    )
}





export default Intro
