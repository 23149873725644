import React from 'react';
import classes from './WhyBenefits.module.css';
import WhyBenefitsItem from './WhyBenefitsItem/WhyBenefitsItem';
import {ReactComponent as TimeSavingIcon} from '../../../assets/svg/time-saving.svg';
import {ReactComponent as OverallSolutionsIcon} from '../../../assets/svg/overall-solutions.svg';
import {ReactComponent as PartnersIcon} from '../../../assets/svg/partners.svg';
import {ReactComponent as SimpleIcon} from '../../../assets/svg/simple.svg';
import {ReactComponent as AvailabilityIcon} from '../../../assets/svg/availability.svg';
import {ReactComponent as ReportingIcon} from '../../../assets/svg/reporting.svg';
import {ReactComponent as PortalIcon} from '../../../assets/svg/portal.svg';
import {ReactComponent as CostIcon}from '../../../assets/svg/cost.svg';
import LanguageContext from '../../../context/language-context';


const WhyBenefitsContent2 = [
    {id:1 , identifier:'timesaving', icon:<TimeSavingIcon />},
    {id:2 , identifier:'reporting', icon:<ReportingIcon />},
    {id:3 , identifier:'solutions', icon:<OverallSolutionsIcon />},
    {id:4 , identifier:'availability' , icon:<AvailabilityIcon />},
    {id:5 , identifier:'simplicity' , icon:<SimpleIcon />},
    {id:6 , identifier:'cost' , icon:<CostIcon />},
    {id:7 , identifier:'portal' , icon:<PortalIcon />},
    {id:8 , identifier:'partners' , icon:<PartnersIcon />},

]


const WhyBenefits = () => {


    return (
        <LanguageContext.Consumer>
            {context => (
                 <div className={classes.WhyBenefits}>
                 <div className={classes.WhyBenefitsOverlay}></div>
                 <div className={classes.WhyBenefitsHeader}>
                     <h4 style={{alignSelf:'flex-start'}} >{context.text.why.benefits.headline}</h4>
                 </div>
                 <div className={classes.WhyBenefitsItemWrapper}>
                     {WhyBenefitsContent2.map(benefit => (
                         <WhyBenefitsItem key={benefit.id} icon={benefit.icon} id={benefit.id} title={context.text.why.benefits[benefit.identifier].title} paragraph={context.text.why.benefits[benefit.identifier].paragraph} />
                     ))}
                 
                 </div>
                 
             </div>

            )}


        </LanguageContext.Consumer>
       
    )
}

export default WhyBenefits
