import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import classes from './ContactMap.module.css';

const ContactMap = (props) => {
  return (
    <div className={classes.ContactMap}>
      <div className={classes.ContactMapContainer}>
        <Map
          google={props.google}
          initialCenter={{
            lat: 62.444018,
            lng: 17.328691,
          }}
          zoom={11}
        >
          <Marker
            title={'Pagoda kontor'}
            name={'Pagoda'}
            position={{ lat: 62.399223039668705, lng: 17.414105291991895 }}
          />
        </Map>
      </div>
    </div>
  );
};
// Disable default map loading container
const LoadingContainer = (props) => null;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCKhyM1srwxO1PC9N0v12L8xyTiqISj8yg',
  /* LoadingContainer: LoadingContainer */
})(ContactMap);
