import React from 'react';
import classes from './ServiceAir.module.css';
import AirImage from '../../../assets/images/services/airfreight.jpg';

const ServiceAir = (props) => {
  
    return (
        <div id='airfreight' className={classes.ServiceAir}>
            <h4 style={{alignSelf:'flex-start'}} >{props.title}</h4>
            <div className={props.styles}>
               
                <p >
                    {props.paragraph}
                </p>
                <img  src={AirImage} alt=""/>
                

            </div>
            
        </div>
    )
}

export default ServiceAir
