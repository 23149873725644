import React from 'react';
import classes from './ServiceWarehouse.module.css';
import WarehouseImage from '../../../assets/images/services/warehouse.jpg';

const ServiceWarehouse = (props) => {
    
    return (
        <div id='warehouse' className={classes.ServiceWarehouse}>
            <h4>{props.title}</h4>
            <div className={props.styles}>
                
                <p>
                    {props.paragraph} 
                </p>
                <img src={WarehouseImage} alt=""/>
                
                

            </div>
            
        </div>
    )
}

export default ServiceWarehouse
