import React from 'react';
import classes from './SystemVisual.module.css';
import Computer from '../../../../assets/images/computer.png';
import Mobile from '../../../../assets/images/mobile.png';

const SystemVisual = () => {
    return (
        <div className={classes.SystemVisual}>
            <div>
                <img className={classes.SystemVisualComputer} src={Computer} alt=""/>
                <img className={classes.SystemVisualMobile} src={Mobile} alt=""/>
            </div>

        </div>
    )
}

export default SystemVisual
