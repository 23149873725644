import React from 'react';
import classes from './ContactFind.module.css';
import ContactMap from './ContactMap/ContactMap';
import LanguageContext from '../../../context/language-context';

const ContactFind = (props) => {
    return (

        <LanguageContext.Consumer>
            {context => (
        
        <div className={classes.ContactFind}>
            <div className={classes.ContactFindDescription}>
                <h2>{context.text.contact.contactfind.headline}</h2>
                <p>{context.text.contact.contactfind.paragraph}
                </p>

            </div>
            

            <ContactMap />
           
        </div>

        )}
        </LanguageContext.Consumer>
    )
}

export default ContactFind
