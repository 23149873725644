const text = {
  en: {
    nav: {
      services: 'Services',
      why: 'Why',
      about: 'About us',
      contact: 'Contact',
    },
    home: {
      hero: {
        road: 'Road Freight',
        leadtimes: 'Precise, reliable lead times',
        air: 'Air Freight',
        speed: 'Speed, efficiency and flexibility',
        sea: 'Ocean freight',
        cost: 'Cost efficient, reliable and sustainable',
      },
      intro: {
        solutions: 'We offer complete transport solutions to suit your needs.',
        article:
          'Pagoda Logistics is a Northern Swedish company based in Sundsvall. We have over 30 years of experience in the logistics industry, nationally and globally.Our mission is to provide small and medium-sized companies the same or better level of service, conditions and possibilities as the larger logistics companies in metropolitan areas.',
        road: 'Road Freight',
        air: 'Air Freight',
        sea: 'Ocean Freight',
      },
      system: {
        solutions: 'Leading edge system solutions',
        portal:
          'Our comprehensive shipping portal “Wisdom” meets all the needs of your company with regard to bookings, prices and follow-up of shipments.',
      },
    },
    services: {
      header: {
        headline: 'Transportation',
        description:
          'We help you choose the right shipping method according to your needs and your shipment will receive personal care.',
      },
      intro: {
        title:
          'Pagoda offers a wide range of transport services and with a choice of service levels',
        paragraph:
          'Depending on weight, volume, destination, delivery time and budget, we will find the optimal solution for you.',
      },
      airfreight: {
        title: 'Air freight',
        paragraph:
          'The natural alternative for time-critical transportation around the world. We offer various options, from express to economy. Whether it is time, price or flexibility that is crucial, we have a solution for you. We deliver door-to-door and ensure correct documentation for import, export and customs.',
      },
      roadfreight: {
        title: 'Road freight',
        paragraph:
          'Road freight is a reliable, flexible and cost-effective way to transport goods within Sweden and Europe. Whether you choose a direct truck for non-stop transport or joint loading, you have the option of pick-up from several locations for consolidation and further delivery. We can also offer special transport for heavy duty and long goods throughout Europe.',
      },
      seafreight: {
        title: 'Ocean freight',
        paragraph:
          'In most cases, ocean freight is the best option when goods need to be shipped intercontinental and delivery time is not the most important factor. Ocean freight is the most cost-effective and environmentally sound choice for transporting goods. We deliver door-to-door and handle both fully loaded containers (FCL) and smaller goods (LCL) where we consolidate to make it as cost-effective as possible.',
      },
      warehouse: {
        title: 'Warehouse and contract logistics',
        paragraph:
          'To complete the logistics chain, we offer warehousing and handling. For example, if you are in need of temporary warehouse space, repackaging, call-off or arrival inspection we have a solution for you. We have solid expertise and experience in contract logistics.',
      },
      consulting: {
        title: 'Consulting',
        paragraph:
          'With our combined experience and expertise in the industry, we can help with everything in your logistics chain. We are the impartial player who, with first-class expertise, provides you with support, consultation, specialized advice and solutions to increase the growth of your company. If you need transport coordination or wish to digitize your logistics, we can be the helping hand you are looking for. In addition to transport services, we are also available for consulting assignments in freight, customs, logistics and freight forwarding. Sometimes the logistics issue for the small cap can be very large, but we make the complex to be perceived as simple for your business.',
      },
    },
    why: {
      header: {
        headline: 'Why Pagoda?',
        description:
          'We strive to create the right conditions for our customers, so they can focus on their core business instead of logistics.',
      },
      intro: {
        headline: 'Pagoda offers service and control of the entire logistic chain',
        realtime: 'Real-time freight tracking ',
        doortodoor: 'Door-to-Door Service',
        timecritical: 'Time-critical transports',
      },
      benefits: {
        headline: 'Benefits of using Pagoda',
        timesaving: {
          title: 'Time-saving',
          paragraph:
            'Easy booking process and handling of shipping documents in the system. With efficient and proactive deviation management you save time by always being informed.',
        },
        reporting: {
          title: 'Reporting',
          paragraph:
            'As our customer you will receive regular reports and follow-ups. We can also tailor reports to suit to your needs. We provide efficient and proactive deviation management, so you save time by being informed',
        },
        solutions: {
          title: 'Overall solution',
          paragraph:
            'We handle all flows within import, export and domestic primarily via air, sea and road. We take care of your delivery regardless of what, how and where you want to send it - everything from the smallest package to a full container.',
        },
        availability: {
          title: 'Availability',
          paragraph:
            'Even though our digital solutions are leading edge, we are ALWAYS available with personalized service to make the process secure and simple for you.',
        },
        simplicity: {
          title: 'Simplicity',
          paragraph:
            'With us as a partner you can put all focus on your business. We help you choose the right shipping method and manage your delivery from door to door, taking care of all documentation and customs handling along the way.',
        },
        cost: {
          title: 'Cost Effectiveness',
          paragraph:
            'We handle large quantities of goods which means we can offer truly competitive prices. Carriers’ strengths vary depending on destinations, type of goods and method of transport, no one is best at everything. We make sure your goods get the most cost-effective management from start to finish.',
        },
        portal: {
          title: 'Customer Portal',
          paragraph:
            'Wisdom is our own customer portal with several features that make it easy and efficient for you to handle freight data. Here you have access to foreseeable follow-up and simple bookings.',
        },
        partners: {
          title: 'Partners',
          paragraph:
            'We only work with the strongest performers in the market and, through our partnerships, you as a customer gain access to all the benefits.',
        },
      },
    },
    about: {
      profile: {
        info: {
          veronica:
            'Natural born leader filling her environment with a lot of positive energy. She is a result-oriented economist who is stimulated and challenged by working towards set goals. Has many years’ experience in the logistics industry and always in full control of debit and credit',
          mats:
            'Has gained many useful experiences by having the world as field of work in senior positions in various areas. Works a bit like a Swiss army knife, i.e. useful for most things. Great interest in business & building relationships and like to have fun at work.',
          claes:
            'West Coast-Northlander” motivated by developing profitable business and good relationships with customers and suppliers, always with a twinkle in his eye. Has devoted his entire professional career to logistics and purchasing in an international environment with focus on Asia, Europe and the USA.',
          andreas:
            'IT-guru with depth and width, always with technology in focus leaving his colleagues not always up to date. Is a creative problem solver with many years’ experience in logistics and logistics systems both as a developer and administrator.',
        },
      },
    },
    contact: {
      header: {
        headline: 'Contact',
        description: 'Building trust, one transaction at a time.',
      },
      available: {
        headline: 'Mats are always available',
      },
      contactform: {
        headline: 'Contact form',
        paragraph:
          'Get in touch with us by phone provide your details in the form and we will contact you shortly.',
        form: {
          company: 'Business*',
          firstname: 'First name*',
          lastname: 'Surname*',
          phone: 'Phone number',
          email: 'Email*',
          send: 'Send',
        },
      },
      contactfind: {
        headline: 'Our office is located in Alnö, close to Sundsvall.',
        paragraph: 'You are most welcome to visit us at: Raholmsvägen 100, 865 92 Alnö!',
      },
    },
  },
  se: {
    nav: {
      services: 'Tjänster',
      why: 'Varför',
      about: 'Om oss',
      contact: 'Kontakt',
    },
    home: {
      hero: {
        road: 'Vägtransport',
        leadtimes: 'Ledtider med precision och tillförlitlighet',
        air: 'Flygtransport',
        speed: 'Snabbhet, effektivitet och flexibilitet',
        sea: 'Sjötransport',
        cost: 'Kostnadseffektivt, pålitligt och hållbart',
      },
      intro: {
        solutions: 'Vi erbjuder helhetslösningar för era transporter.',
        article:
          'Pagoda Logistics är ett Norrländskt företag med säte i Sundsvall. Vi har över 30 års erfarenhet inom logistikbranschen, nationellt och globalt. Vår affärside är att ge små och medelstora företag samma service, villkor och förutsättningar som de stora företagen i storstadsregionerna.',
        air: 'Flygtransport',
        road: 'Vägtransport',
        sea: 'Sjötransport',
      },
      system: {
        solutions: 'Systemlösningar i framkant',
        portal:
          'Med vår heltäckande fraktportal “Wisdom” tillgodoses alla behov som ditt företag har gällande bokningar, priser och uppföljning av försändelser.',
      },
    },
    services: {
      header: {
        headline: 'Transporttjänster',
        description:
          'Vi hjälper dig att välja fraktsätt efter vad som är viktigt för dig och din sändning får ett personligt omhändertagande.',
      },
      intro: {
        title: 'Pagoda erbjuder ett brett transportutbud och olika servicenivåer',
        paragraph:
          'Beroende på vikt, volym, destination, leveranstidskrav och budget hittar vi den optimala lösningen för dig.',
      },
      airfreight: {
        title: 'Flygtransporter',
        paragraph:
          'Det naturliga alternativet för tidskritiska transporter världen över. Vi erbjuder olika nivåer från express till economy. Oavsett om det är tiden, priset eller flexibiliteten som är avgörande har vi en lösning för dig. Vi levererar dörr-dörr och säkerställer korrekt dokumentation för import, export och tull.',
      },
      roadfreight: {
        title: 'Vägtransporter',
        paragraph:
          'Vägfrakt är ett pålitligt, flexibelt och kostnadseffektivt sätt för frakter inom Sverige och Europa. Oavsett om du väljer en direktbil för non-stop-transport eller samlastning, har du möjlighet till upphämtning från flera platser för konsolidering och vidare leverans. Vi kan även erbjuda specialtransporter för riktigt tungt och långt gods inom Europa.',
      },
      seafreight: {
        title: 'Sjötransporter',
        paragraph:
          'I de allra flesta fall är sjöfrakt alternativet när gods skall skickas interkontinentalt och leveranstiden inte är viktigast. Sjöfrakt är det mest kostnadseffektiva och ett miljömässigt bra val att transportera gods. Vi levererar dörr-dörr och hanterar både fullastade containers (FCL) och mindre gods (LCL) där vi samlastar för att göra det så kostnadseffektivt som möjligt.',
      },
      warehouse: {
        title: 'Lager och kontraktslogistik',
        paragraph:
          'För att göra logistikkedjan komplett erbjuder vi lagerhållning och hantering. Är ni exempelvis  i behov av tillfälliga lagerytor, ompackning,  avrop eller ankomstkontroll löser vi det åt er. Vi har gedigen kompetens och erfarenhet av kontraktslogistik.',
      },
      consulting: {
        title: 'Konsulting',
        paragraph:
          'Med vår samlade erfarenhet och expertis inom branschen kan vi vara behjälplig med allt i er logistikkedja. Vi är den opartiske aktören som med förstklassig kompetens ger er support, konsultation, specialiserade råd och lösningar för att öka tillväxten för ert företag. Är ni i behov av transportkoordinering eller behöver digitalisera er logistik så kan vi vara den hjälpande handen ni söker. Förutom transporttjänster finns vi också tillgängliga för konsultuppdrag inom frakt, tull, logistik och spedition. Ibland kan logistikfrågan för den lilla bli väldigt stor men vi får det komplexa att upplevas som simpelt för er verksamhet. ',
      },
    },
    why: {
      header: {
        headline: 'Varför Pagoda?',
        description:
          'Vi vill skapa förutsättningar för företag att fokusera på kärnverksamheten istället för logistiken.',
      },
      intro: {
        headline: 'Pagoda erbjuder service och kontroll över hela transportkedjan',
        realtime: 'Realtidsbevakning',
        doortodoor: 'Dörr-Dörr Service',
        timecritical: 'Tidskritiska transporter',
      },
      benefits: {
        headline: 'Fördelar med Pagoda',
        timesaving: {
          title: 'Tidsbesparing',
          paragraph:
            'Enkel bokningsprocess och hantering av fraktdokument i systemet. Med effektiv och proaktiv avvikelsehantering sparar ni tid genom att alltid vara informerad.',
        },
        reporting: {
          title: 'Rapportering',
          paragraph:
            'Som kund till oss får du regelbundna rapporter och uppföljning. Vi kan även skräddarsy rapporten efter era behov.',
        },
        solutions: {
          title: 'Helhetslösning',
          paragraph:
            'Vi hanterar alla flöden inom import, export och inrikes primärt via flyg, sjö och väg. Vi tar hand om din leverans oberoende på vad, hur och vart du vill skicka, allt från minsta paket till full container.',
        },
        availability: {
          title: 'Tillgänglighet',
          paragraph:
            'Även om våra digitala lösningar ligger i absolut framkant finns vi ALLTID tillgängliga med personlig service för att det ska bli tryggt och enkelt för dig.',
        },
        simplicity: {
          title: 'Enkelhet',
          paragraph:
            'Med oss som partner kan ni kan lägga allt fokus på er verksamhet. Vi hjälper dig att välja rätt fraktsätt och hanterar din leverans från dörr till dörr och ombesörjer all dokument- och tullhantering på vägen.',
        },
        cost: {
          title: 'Kostnadseffektivitet',
          paragraph:
            'Vi hanterar stora mängder gods vilket gör att vi kan erbjuda riktigt konkurrenskraftiga priser. Transportörernas styrkor varierar beroende på destinationer, typ av gods och fraktsätt, ingen är bäst på allt. Vi ser till att ditt gods får den mest kostnadseffektiva hanteringen från start till mål.',
        },
        portal: {
          title: 'Kundportal',
          paragraph:
            'Wisdom är vår egen kundportal med flertalet funktioner som gör det enkelt och effektivt för dig att hantera fraktdata. Här får du tillgång till överskådlig uppföljning och enkla bokningar. ',
        },
        partners: {
          title: 'Partners',
          paragraph:
            'Vi arbetar endast med de starkaste aktörerna på marknaden och via vårt partnerskap får du som kund tillgång till alla dess fördelar.',
        },
      },
    },
    about: {
      profile: {
        info: {
          veronica:
            'Naturlig ledare med mycket positiv energi som sprids till sin omgivning. Är i grunden en resultatinriktad ekonom som stimuleras och utmanas av att arbeta mot uppsatta mål. Har flerårig erfarenhet av logistikbranschen och ekonomiansvar.',
          mats:
            'Har med världen som arbetsfält i ledande befattningar inom olika områden samlat på sig många nyttiga erfarenheter. Fungerar lite som en schweizisk armekniv - användbar till det mesta. Stort intresse för affärer & relationsbyggande samt gillar att ha roligt på jobbet.',
          claes:
            'Västkust-norrlänning som motiveras av att utveckla lönsamma affärer och bra relationer med kunder och leverantörer, alltid med glimten i ögat. Har ägnat hela yrkeslivet åt logistik och inköp i en internationell miljö med inriktning på Asien, Europa och USA.',
          andreas:
            'IT-guru med djup och bredd som alltid har tekniken i fokus vilket gör att kollegorna inte alltid hänger med. Är en kreativ problemlösare med mångarig erfarenhet inom logistik och logistiksystem både som utvecklare och administratör',
        },
      },
    },
    contact: {
      header: {
        headline: 'Kontakt',
        description: 'Vi bygger förtroende, ett uppdrag i taget.',
      },
      available: {
        headline: 'Mats är alltid tillgänglig',
      },
      contactform: {
        headline: 'Kontaktformulär',
        paragraph:
          'Vill ni komma i kontakt med oss går det bra att ringa eller fyll i era uppgifter i formuläret så kontaktar vi er inom kort.',
        form: {
          company: 'Företag*',
          firstname: 'Förnamn*',
          lastname: 'Efternamn*',
          phone: 'Telefonnummer',
          email: 'Email*',
          send: 'Skicka',
        },
      },
      contactfind: {
        headline: 'Hitta oss på vårt kontor på Alnö utanför Sundsvall!',
        paragraph: 'Ni är varmt välkomna att besöka oss på Raholmsvägen 100, 865 92 Alnö.',
      },
    },
  },
};

export default text;
