import React,{useEffect, useState} from 'react'
import classes from './ServicesHeader.module.css';
import BackgroundImageFull from '../../../assets/images/backgrounds/services-bw1920-thin2.jpg';
import BackgroundImageSmall from '../../../assets/images/backgrounds/services-bw800-small.jpg';


const ServicesHeader = (props) => {

    const [winWidth, setWinWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWinWidth(window.innerWidth))
       
    })

  
    return (
        
        <React.Fragment>

            <div className={classes.ServicesHeaderHero}>
           
                <div className={classes.ServicesHeaderCover}>
                <div className={classes.ServicesHeaderOverlay}></div> 
                    <img src={winWidth > 1100? BackgroundImageFull: BackgroundImageSmall } alt=""/>
                </div>
             
                    <h2 className={classes.ServicesHeaderText}>{props.headline}</h2>
                    <div className={classes.TextBox}>
                        <p>
                            {props.description}
                        </p>
                    </div>
                
            </div>

            <div style={{width:"100%", height:"2px", background:'#453748'}}></div>


        </React.Fragment>
    )
}

export default ServicesHeader
