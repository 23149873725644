import React from 'react';
import classes from './Services.module.css';
import ServiceAir from './ServiceAir/ServiceAir';
import ServiceRoad from './ServiceRoad/ServiceRoad';
import ServiceSea from './ServiceSea/ServiceSea';
import ServiceWarehouse from './ServiceWarehouse/ServiceWarehouse';
import ServiceConsulting from './ServiceConsulting/ServiceConsulting';
import Header from './ServicesHeader/ServicesHeader';
import LanguageContext from '../../context/language-context';



const Services = (props) => {

    return (

        <LanguageContext.Consumer>
                {context => (
                        <div className={classes.Services}>
                                    <div className={classes.MainServices}>

                                        <div className={classes.ServicesBodyOverlay}>
                                        </div>  
                                        <Header headline={context.text.services.header.headline} description={context.text.services.header.description}  /> 
                                        
                            
                                            <div className={classes.ServicesBody}>
                                            
                                                <div className={classes.ServicesIntro}>
                                                    <h3>{context.text.services.intro.title}</h3>
                                                    <p>{context.text.services.intro.paragraph}</p>
                                                </div>
                                            
                                                <ServiceAir title={context.text.services.airfreight.title} paragraph={context.text.services.airfreight.paragraph} styles={classes.ServicesDescription} />
                                                <ServiceRoad title={context.text.services.roadfreight.title} paragraph={context.text.services.roadfreight.paragraph} styles={classes.ServicesDescription} />
                                                <ServiceSea title={context.text.services.seafreight.title} paragraph={context.text.services.seafreight.paragraph} styles={classes.ServicesDescription} />
                                                
                                                
                                            </div>
                                    </div>

                                 <div style={{backgroundColor:'#ddcede'}}>
                                    <ServiceConsulting title={context.text.services.consulting.title} paragraph={context.text.services.consulting.paragraph} styles={classes.ServicesWarehouse} />
                                    
                                </div> 
                                <div style={{backgroundColor:'white'}}>

                                    <ServiceWarehouse title={context.text.services.warehouse.title} paragraph={context.text.services.warehouse.paragraph} styles={classes.ServicesWarehouse} />
                                </div>

                        </div>

                )}
                
        </LanguageContext.Consumer>
        )

}

export default Services
