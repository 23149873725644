import React from 'react';
import classes from './ContactAvailable.module.css';
import ClaesImage from '../../../assets/images/profiles/ContactClaes400.jpg';
import MatsImage from '../../../assets/images/profiles/ContactMats400.jpg';
import LanguageContext from '../../../context/language-context';
import phone from '../../../assets/svg/phone-24px.svg';
import email from '../../../assets/svg/email-24px.svg';

const ContactAvailable = () => {
  return (
    <LanguageContext.Consumer>
      {(context) => (
        <div className={classes.ContactAvailable}>
          <h2>{context.text.contact.available.headline}</h2>
          <div className={classes.ContactContacts}>
            {/*  <div className={classes.ContactPerson}>

                    <img className={classes.ContactProfile} src={ClaesImage} alt="Claes Profile image"/>
                    <div className={classes.ContactProfileInfo}>
                        <img src={phone} alt="phone icon"/>
                        <a href="tel:+46 70 5385816">070-538 58 16</a>

                    </div>
                    <div className={classes.ContactProfileInfo}>
                        <img src={email} alt="email icon"/>
                        <a href = "mailto: claes@pagodalog.com">claes@pagodalog.com</a>

                    </div>
                    
                   
                </div> */}
            <div className={classes.ContactPerson}>
              <img className={classes.ContactProfile} src={MatsImage} alt='Mats Profile image' />
              <div className={classes.ContactProfileInfo}>
                <img src={phone} alt='phone icon' />
                <a href='tel:+46 70 6691110'>070-669 11 10</a>
              </div>
              <div className={classes.ContactProfileInfo}>
                <img src={email} alt='email icon' />
                <a href='mailto: mats@pagodalog.com'>mats@pagodalog.com</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </LanguageContext.Consumer>
  );
};

export default ContactAvailable;
