import React from 'react';
import classes from './ServiceSea.module.css';
import SeaImage from '../../../assets/images/services/seafreight.jpg';

const ServiceSea = (props) => {
    
    return (
        <div id='seafreight' className={classes.ServiceSea}>
            <h4 style={{alignSelf:'flex-start'}}>{props.title}</h4>
            <div className={props.styles}>
                
                <p>
                {props.paragraph} 
                </p>
                <img src={SeaImage} alt=""/>
                
                

            </div>
            
        </div>
    )
}

export default ServiceSea
