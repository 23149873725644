import React from 'react';
import classes from './AboutHeader.module.css';
import GroupImage from '../../../assets/images/backgrounds/group-photo2.png';

const AboutHeader = (props) => {
  return (
    <div className={classes.AboutHeader}>
      <img width='100%' src={GroupImage} alt='' />
    </div>
  );
};

export default AboutHeader;
