import React from 'react'
import classes from './Contact.module.css';
import Header from './ContactHeader/ContactHeader';
import ContactAvailable from './ContactAvailable/ContactAvailable.js';
import ContactForm from './ContactForm/ContactForm.js';
import ContactFind from './ContactFind/ContactFind.js';



const Contact = () => {

    const headline = 'Kontakt';
    const description = 'Vi vill skapa förutsättningarna för företag att fokuserar på produktionskedjan istället för logistiken.';


    return (
        <div className={classes.Contact}>

            <Header headline={headline} description={description} />
            <ContactAvailable />
            <ContactForm />
            <ContactFind />

        </div>
    )
}

export default Contact
