import React from "react";
import { render } from "react-dom";
import { TransitionMotion, spring } from "react-motion";
import "./style.css";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      error: props.error || "",
      label: props.label || "Label",
      /* clean: props.clean */
    };
  }

/*   componentDidUpdate(prevProps) {
    
    if(prevProps.clean !== this.props.clean){
      this.setState({...this.state, value: ""})
    }
    
  } */

  changeValue(event) {
    const value = event.target.value;
    this.setState({ value, error: "" });
  }

  handleKeyPress(event) {
    if (event.which === 13) {
      this.setState({ value: this.props.predicted });
    }
  }

  handleOnBlur() {
    
    this.props.handleBlur(this.props.type, this.state.value)
    this.setState({ active: false })
  }

  render() {
    const { active, value, error, label } = this.state;
    const { predicted, locked } = this.props;
    const fieldClassName = `field ${(locked ? active : active || value) &&
      "active"} ${locked && !active && "locked"}`;

    return (
      <div className={fieldClassName}>
        {active &&
          value &&
          predicted &&
          predicted.includes(value) && <p className="predicted">{predicted}</p>}
        <input
          id={this.props.id}
          type="text"
          value={value}
          placeholder={label}
          onChange={this.changeValue.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => this.handleOnBlur()}
        />
        <label htmlFor={this.props.id} className={error && "error"}>
          {error || label}
        </label>
      </div>
    );
  }
}

export default Input;
