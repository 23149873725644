import React, {useState, useEffect} from 'react';
import classes from './ContactForm.module.css';
import TextBox from './ContactFormInput/ContactFormInput';
import LanguageContext from '../../../context/language-context';


const inputFields = [
    {id: 1, label: 'Företag*',type:'company'},
    {id: 2, label: 'Förnamn*',type:'firstname'},
    {id: 3, label: 'Efternamn*',type:'lastname'},
    {id: 4, label: 'Telefonnummer',type:'phone'},
    {id: 5, label: 'Email*',type:'email'},
]

const ContactForm = () => {

    const [loading, setLoading] = useState(false);
    const [contactInfo, setContact] = useState({
        company:'',
        firstname:'',
        lastname:'',
        phone:'',
        email:''
    
    });
    const [contactSent, setContactSent] = useState(false);
   

    const handleSubmit = () => {
        console.log('contactInfo',contactInfo)
        setLoading(true);
        fetch('/api/sendcontact', {
            headers: {
                'Accept':'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(contactInfo)
        }).then(resp => resp.json()).then(resp => {
            
            setContactSent(true);
            setLoading(false);
           
        }).catch(reject => {
           
            setContactSent(true);
            setLoading(false);
        })
        
    } 
    const handleOnBlur = (type, value) => {
        setContact({...contactInfo,[type]:value});
    } 



    return (

        <LanguageContext.Consumer>

            {context => (
                
            <div className={classes.ContactForm}>
            
                <div  className={classes.ContactFormInfo} style={contactSent?{display:'none'}:null}>

                    <p>
                        {context.text.contact.contactform.paragraph}
                    </p>

                </div>
                <div className={classes.ContactFormForm} style={contactSent?{position:'relative'}:{display:'flex'}}>
                 {contactSent? <h2 className={classes.contactFormGreeting}>Tack för ansökan om kontakt</h2>
                    :<h2 className={classes.contactFormHeadline}>{context.text.contact.contactform.headline}</h2>} 
                        


                        <div id='contactform' className={classes.InputFields} style={contactSent?{visibility:'hidden'}:null}>
                            {inputFields.map(field => (
                                
                                <TextBox
                                    key={field.id}
                                    id={field.id}
                                    label={context.text.contact.contactform.form[field.type]}
                                    predicted=""
                                    locked={false}
                                    active={false}
                                    handleBlur={handleOnBlur}
                                    type={field.type}
                                    value={contactInfo[field.type]}
                                  /*   clean={loading} */
                                />
                            ))}
                                    {loading?<p>Loading...</p>:<input onClick={() => handleSubmit()} className={classes.contactSubmitButton} type='button' value={context.text.contact.contactform.form.send} />

                                    }
                            
                        </div>
                      
              

                </div>
            
        </div>
            )}
        </LanguageContext.Consumer>
        




       
       
    )
}

export default ContactForm
