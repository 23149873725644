import React, {useState, useEffect} from 'react'
import classes from './ContactHeader.module.css';
 import WorldMapSvg from '../../Svg/WorldMap';
 import LanguageContext from '../../../context/language-context';

 const worldMapValues = {
    large: [1000, 100,0, 1],
    medium: [700, 50, 0, 1],
    small: [650, 0, 0, 0.3]
}

const ContactHeader = (props) => {
    const [winWidth, setWinWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWinWidth(window.innerWidth))
       
    })

    let mapValues = worldMapValues.large;

    if(winWidth < 1277 && winWidth > 963) {
        mapValues = worldMapValues.medium;
    }else if(winWidth < 963){
        mapValues = worldMapValues.small
    }


    return (

        <LanguageContext.Consumer>
            {context => (

                <React.Fragment>

                    <div className={classes.ContactHeaderHero}>
                
                        <div className={classes.ContactHeaderCover}>
                    
                            <div className={classes.TextBox}>
                                    <h2>{context.text.contact.header.headline}</h2>
                                    <p>
                                        {context.text.contact.header.description}
                                    </p>
                            </div>
                            <div className={classes.ContactBackgroundIllustration}>

                                <WorldMapSvg width={mapValues[0]} translateX={mapValues[1]} translateY={mapValues[2]} opacity={mapValues[3]} />
                            </div>
                        
                        </div>

                    </div>

                    <div style={{width:"100%", height:"2px", background:'#453748'}}></div>


                </React.Fragment>
            )}
        </LanguageContext.Consumer>
    )
}

export default ContactHeader
