import React from 'react';
import classes from './BurgerMenu.module.css';
import {slide as Menu} from 'react-burger-menu';
import {NavLink, Link} from 'react-router-dom';
import BurgerMenuFooter from './BurgerMenuFooter/BurgerMenuFooter';
import logo from '../../../assets/images/logotypes/mainlogo.png';
import LanguageContext from '../../../context/language-context';



var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '16px'
    },
    bmBurgerBars: {
      background: '#e7e7e7',
      height:'4px'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      width: '280px'
     
    },
    bmMenu: {
      background: 'rgb(61, 64, 76)',
      fontSize: '1.15em',
      height:'100vh',
      width: '100p%'
      
    },
  /*   bmMorphShape: {
      fill: '#373a47',
      left: '0px',
      width:'200px'

    }, */
    bmItemList: {
      color: '#b8b7ad',
      display:'flex',
      height:'50%',
      flexDirection:'column',
      padding: '0em',
      left:'-180px',
      width:'300px'
    },
    bmItem: {
      display: 'inline-block',
      textDecoration:'none',
      color:'white',
      padding:'15px 0',
      outline:'none'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      height:'100vh'
    }
  }
   

class BurgerMenu extends React.Component {

    state = {
      menuOpen: false
    }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  
  closeMenu () {
    this.setState({menuOpen: false})
  }

   
    render() {
      return (

        <LanguageContext>
          {context => (

            <Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} styles={styles} right={true} className={classes.BurgerMenu} >
                <NavLink style={{paddingLeft: 'calc(47% - 60px)'}} to="/" ><img className={classes.BurgerMenuLogotype} src={logo} alt="Pagoda Icon"/></NavLink> 
                
                <NavLink className={classes.LinkItem} onClick={() => this.closeMenu()}  to="/services"><span>{context.text.nav.services}</span></NavLink>
                <NavLink className={classes.LinkItem} onClick={() => this.closeMenu()}  to="/why"><span>{context.text.nav.why}</span></NavLink>
                <NavLink className={classes.LinkItem} onClick={() => this.closeMenu()}  to="/about"><span>{context.text.nav.about}</span></NavLink>
                <NavLink className={classes.LinkItem} onClick={() => this.closeMenu()}  to="/contact"><span>{context.text.nav.contact}</span></NavLink>
                <BurgerMenuFooter closeMenu={() => this.closeMenu()} className={classes.LinkItem} />
              

            </Menu>


          )}

        </LanguageContext>

 
)

    } 
}

export default BurgerMenu

