import React,{useEffect, useState} from 'react'
import classes from './WhyHeader.module.css';
import BackgroundImageFull from '../../../assets/images/backgrounds/why-1920-large.jpg';
import BackgroundImageSmall from '../../../assets/images/backgrounds/why-1920-large.jpg';
import LanguageContext from '../../../context/language-context';


const WhyHeader = () => {

    const [winWidth, setWinWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWinWidth(window.innerWidth))
       
    })

  
    return (
        <LanguageContext.Consumer>
            {context => (
                <React.Fragment>


                    <div className={classes.WhyHeaderHero}>
                
                        <div className={classes.WhyHeaderCover}>
                        <div className={classes.WhyHeaderOverlay}></div> 
                            <img src={winWidth > 1100? BackgroundImageFull: BackgroundImageSmall } alt=""/>
                        </div>
                    
                            <h2 className={classes.WhyHeaderText}>{context.text.why.header.headline}</h2>
                            <div className={classes.TextBox}>
                                <p>
                                {context.text.why.header.description}
                                </p>
                            </div>
                        
                    </div>
    
                    <div style={{width:"100%", height:"2px", background:'#453748'}}></div>
                </React.Fragment>
            )}




        </LanguageContext.Consumer>



    )
}

export default WhyHeader
