import React from 'react';
import classes from './ServiceConsulting.module.css';
import ConsultingImage from '../../../assets/images/services/consulting.jpg';

const ServiceConsulting = (props) => {
    
    return (
        <div id='warehouse' className={classes.ServiceConsulting}>
           
            <div className={classes.ConsultingContent}>
                <div>
                    <h4>{props.title}</h4>
                    <div className={classes.ParagraphContainer}>
                        <p>{props.paragraph} </p>
                    </div>
                </div>
                <div className={classes.ConsultingImageContainer}>
                    <img src={ConsultingImage} alt=""/>
                </div>


            </div>
            
            
            
        </div>
    )
}

export default ServiceConsulting
