import React, { Component } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {isIE} from "react-device-detect";
import DesktopLayout from './hoc/Layout/DesktopLayout';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import About from './components/About/About';
import Why from './components/Why/Why';
import Contact from './components/Contact/Contact';
import LanguageContext from './context/language-context';
import Text from './text/text';


function renderWithLayout(Component, Layout, color='black') {


  return (<Layout >
      <Component />
    </Layout>)
  
  }

class App extends Component {

  state = { 
    lang: 'se',
    isIE:false
  }
 
 selectLanguage = (lang) => {
 
  this.setState({lang: lang})
 }
 render() {
  

   /*  if (isIE) return <div> IE is not supported. Download Chrome/Opera/Firefox </div> */
    if (isIE && !this.state.isIE) this.setState({...this.state,isIE:true})

    let routes = (
        <Switch>
    
        
          <Route path="/" render={() => renderWithLayout(Home, DesktopLayout)} exact/>
          <Route path="/about" render={() => renderWithLayout(About, DesktopLayout)} exact/>
          <Route path="/services" render={() => renderWithLayout(Services, DesktopLayout)} exact/>
          <Route path="/why" render={() => renderWithLayout(Why, DesktopLayout)} exact/>
          <Route path="/contact" render={() => renderWithLayout(Contact, DesktopLayout)} exact/>
          <Redirect to="/" />
        </Switch>
      );
    

    
    return (
          
     
         <LanguageContext.Provider value={{
           lang: this.state.lang,
           selectLang: this.selectLanguage,
           text: Text[this.state.lang],
           isIE: this.state.isIE
         }}>

           {routes}
         </LanguageContext.Provider>
 
        
    );
  
  }
}

export default App;
