import React from 'react';
import classes from './ServiceRoad.module.css';
import RoadImage from '../../../assets/images/services/roadfreight.jpg';

const ServiceRoad = (props) => {
    
    return (
        <div id='roadfreight' className={classes.ServiceRoad}>
            <h4 style={{alignSelf:'flex-end'}}>{props.title}</h4>
            <div className={props.styles}>
                <img src={RoadImage} alt=""/>
                <p>
                {props.paragraph} 
                </p>
                

            </div>
            
        </div>
    )
}

export default ServiceRoad
