import React from 'react'
import classes from './Why.module.css';
import Header from './WhyHeader/WhyHeader';
import WhyIntro from './WhyIntro/WhyIntro';
import WhyBenefits from './WhyBenefits/WhyBenefits';


const Why = () => {



    return (
        <div className={classes.Why}>

            <Header />
            <WhyIntro />
            <WhyBenefits />

        </div>
    )
}

export default Why
