import React from 'react';
import classes from './System.module.css';
import SystemExplanation from './SystemExplanation/SystemExplanation';
import SystemVisual from './SystemVisual/SystemVisual';

const System = () => {
    return (
        <div className={classes.HomeSystem}>
            <SystemExplanation />
            <div className={classes.Seperator}></div>
            <SystemVisual />
        </div>
    )
}

export default System
