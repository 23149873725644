import React from 'react';
import classes from './SystemExplanation.module.css';
import LanguageContext from '../../../../context/language-context';

const SystemExplanation = () => {
    return (

        <LanguageContext.Consumer>
            {context => (

                <div className={classes.SystemExplanation}>
                    <h3>{context.text.home.system.solutions}</h3>
                    <p>{context.text.home.system.portal}</p>
                </div>
            )}



        </LanguageContext.Consumer>
    )
}

export default SystemExplanation
