import React, { useContext } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/UI/BurgerMenu/BurgerMenu';
import classes from './DesktopLayout.module.css';
import languageContext from '../../context/language-context';


const DesktopView = (props) => {
   
  const {isIE} = useContext(languageContext);
        
        return ( 
                <React.Fragment>
                    <div className={classes.BurgerMenu}>
                   
                    <Menu  />
                    </div>
                    {isIE? (
                        <div style={{
                            display:'absolute',
                            fontSize:'1.5rem',
                            textAlign:'center'
                    
                    }}>Denna sida är inte optimerad för Internet Explorer</div>
                    ):null}
                    <Navigation />
                    {props.children}
                    <Footer />
                </React.Fragment>
          );
    
}
 
export default DesktopView;
/* class DesktopView extends Component {
   
    render() { 
        
        return ( 
                <React.Fragment>
                    <div className={classes.BurgerMenu}>

                    <Menu  />
                    </div>
                    <Navigation />
                    {this.props.children}
                    <Footer />
                </React.Fragment>
          );
    }
}
 
export default DesktopView; */