import React from 'react';
import classes from './AboutProfile.module.css';
import ProfileSkill from './ProfileSkill/ProfileSkill';



const AboutProfile = (props) => {
    return (
        <div className={classes.AboutProfile}>
                <div className={classes.AboutProfileMobileName}>
                    <h2>{props.fullname}</h2>
                    <a href={`mailto:${props.email}`}>{props.email}</a>
                </div>
            
                <img src={props.image} alt=""/>
                    <div className={classes.ProfileInfo}>
                        <h2>{props.fullname}</h2>
                        <a href={`mailto:${props.email}`}>{props.email}</a>
                        <p>{props.info}</p>

                    </div>
                <ProfileSkill profileIndex={props.profileIndex} skills={props.skills} />

        </div>
    )
}

export default AboutProfile
