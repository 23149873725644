import React from 'react'
import classes from './WhyBenefitsItem.module.css';

const WhyBenefitsItem = (props) => {
    return (
        <div className={classes.WhyBenefitsItem}>
            <div className={classes.WhyBenefitsItemBackground}>
                <div className={classes.WhyBenefitsIcon}>{props.icon}</div>
            </div>
             
            <h3>{props.title}</h3>
            <p>{props.paragraph}</p>
        </div>
    )
}

export default WhyBenefitsItem
