import React from 'react';
import classes from './About.module.css';
import AboutHeader from './AboutHeader/AboutHeader';
import AboutProfile from './AboutProfile/AboutProfile';
// import ClaesImage from '../../assets/images/profiles/Claes400.jpg';
import VeronicaImage from '../../assets/images/profiles/Veronica400.jpg';
import MatsImage from '../../assets/images/profiles/Mats400.jpg';
import AndreasImage from '../../assets/images/profiles/Andreas400.jpg';
import LanguageContext from '../../context/language-context';

const About = () => {
  const profiles = [
    {
      id: 1,
      fullname: 'Veronica Österlund',
      email: 'veronica@pagodalog.com',
      tag: 'veronica',
      image: VeronicaImage,
      skills: { se: ['VD', 'Ekonomi', 'Support'], en: ['CEO', 'Accounting', 'Support'] },
    },
    {
      id: 2,
      fullname: 'Mats Ingelsson',
      email: 'mats@pagodalog.com',
      tag: 'mats',
      image: MatsImage,
      skills: { se: ['Kundansvarig', 'Ekonomi'], en: ['Sales Manager', 'Financial'] },
    },
    /*  {id:3, fullname:'Claes Nilsson',email:'claes@pagodalog.com', tag:'claes', image: ClaesImage, skills:{se:['Kundansvarig','Leverantörsansvarig'],en:['Sales Manager','Purchasing manager']}}, */
    {
      id: 4,
      fullname: 'Andreas Österlund',
      email: 'andreas@pagodalog.com',
      tag: 'andreas',
      image: AndreasImage,
      skills: { se: ['Systemansvarig', 'Support'], en: ['System Manager', 'Support'] },
    },
  ];

  return (
    <LanguageContext>
      {(context) => (
        <div className={classes.About}>
          <AboutHeader /* headline={headline} */ />

          <div className={classes.AboutBody}>
            <div className={classes.AboutBodyOverlay} />
            {profiles.map((profile, index) => (
              <AboutProfile
                email={profile.email}
                fullname={profile.fullname}
                key={profile.id}
                image={profile.image}
                info={context.text.about.profile.info[profile.tag]}
                profileIndex={index}
                skills={profile.skills[context.lang]}
              />
            ))}
          </div>
        </div>
      )}
    </LanguageContext>
  );
};

export default About;
