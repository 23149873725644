
import React from 'react'
import classes from './Hero.module.css';
import clsx from 'clsx';
import { Transition, animated, Trail } from 'react-spring/renderprops';
import LanguageContext from '../../../context/language-context';



const pages = [
  style => (
    <LanguageContext.Consumer>
    {context => (
    <animated.div style={{ ...style}}>
        <div className={classes.HomeHeroRoad}>
        <div className={classes.HomeHeroOverlay}></div>
            <div className={clsx(classes.LeftTextBox, classes.HomeHeroRoadTextBox, classes.HomeHeroTextBox)}>
                <h2>{context.text.home.hero.road}</h2>
                <p>{context.text.home.hero.leadtimes}</p>
            </div>
        </div>
    </animated.div>
                 )}
    </LanguageContext.Consumer>
  ),
  style => (
    <LanguageContext.Consumer>
    {context => (
    <animated.div style={{ ...style}}>
        <div className={classes.HomeHeroAir}>
        <div className={classes.HomeHeroOverlay}></div>
            <div className={clsx(classes.RightTextBox, classes.HomeHeroAirTextBox, classes.HomeHeroTextBox)}>
                        <h2>{context.text.home.hero.air}</h2>
                        <p>{context.text.home.hero.speed} </p>
            </div>
        </div>
    </animated.div>
    )}
    </LanguageContext.Consumer>
  ),
  style => (
    <LanguageContext.Consumer>
    {context => (
    <animated.div style={{ ...style}}>
        <div className={classes.HomeHeroSea}>
        <div className={classes.HomeHeroOverlay}></div>
            <div className={clsx(classes.LeftTextBox, classes.HomeHeroSeaTextBox, classes.HomeHeroTextBox)}>
                 <h2>{context.text.home.hero.sea}</h2>
                 <p>{context.text.home.hero.cost}</p>
            </div>
        </div>
    </animated.div>
      )}
      </LanguageContext.Consumer>
  ),


/*   style => (
    <animated.div style={{ ...style, background: '#B2DBBF' }}>B</animated.div>
  ),
  style => (
    <animated.div style={{ ...style, background: '#12DBBF' }}>C</animated.div>
  ), */
]

export default class App extends React.Component {
  
    state = { index: 0 }

  componentDidMount() {
      setInterval(() => {
        this.setState(state => ({
            index: state.index === 2 ? 0 : state.index + 1,
          }))
      },3000)
  }

  
    
  render() {
    return (
      <div className={classes.HomeHero} >
      
        <Transition
          native
          reset
          unique
          items={this.state.index}
          from={{ opacity: 0, }}
          enter={{ opacity: 1, }}
          leave={{ opacity: 0, }}>
          {index => pages[index]}
        </Transition>
      </div>
    )
  }
}