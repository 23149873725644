import React, { useContext } from 'react';
import classes from './Footer.module.css';
import logo from '../../assets/images/logotypes/mainlogo.png';
import FacebookIcon from '../../assets/svg/Facebook.svg';
import LinkedinIcon from '../../assets/svg/Linkedin.svg';
import TwitterIcon from '../../assets/svg/Twitter.svg';
import PhoneIcon from '../../assets/svg/pagodaphone-24px.svg';
import AddressIcon from '../../assets/svg/home-24px.svg';
import languageContext from '../../context/language-context';

const footer = () => {
  const { isIE } = useContext(languageContext);

  return (
    <footer className={classes.Footer}>
      <section>
        <address>
          <hr />
          <div className={classes.HomeAddress} style={{ display: 'flex' }}>
            <img style={{ alignSelf: 'flex-start' }} src={AddressIcon} alt="address icon" />
            <div style={{ marginLeft: '5px', textAlign: 'left' }}>
              <p>Raholmsvägen 100</p>
              <p>865 92 Alnö</p>
              <p>Sverige</p>
            </div>
          </div>
          <hr />
          <div className={classes.HomeAddress} style={{ display: 'flex' }}>
            <img src={PhoneIcon} alt="phone icon" />
            <a className={classes.PagodaPhone} href="tel:+46 60 558008">
              060-55 80 08
            </a>
          </div>
          <hr />
        </address>
        <div className={classes.SocialMedia}>
          <a href="https://www.facebook.com/PagodaLogistics/" target="_blank">
            <img src={FacebookIcon} alt="Facebook icon" />
          </a>
          <a href="https://www.linkedin.com/company/pagoda-logistics/" target="_blank">
            {' '}
            <img src={LinkedinIcon} alt="Linkedin icon" />
          </a>
          <a href="https://twitter.com/PagodaLogistics" target="_blank">
            <img src={TwitterIcon} alt="Twitter icon" />
          </a>
        </div>
      </section>
      <section style={isIE ? { marginTop: '300px' } : null}>
        <img className={classes.FooterLogotype} src={logo} alt="Pagoda Icon" />

        <address>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <a className={classes.InfoMail} href="mailto: info@pagodalog.com">
              info@pagodalog.com
            </a>
            <p>pagodalog.com</p>
          </div>
        </address>
      </section>
    </footer>
  );
};

export default footer;
