import React from 'react'
import classes from './Navigation.module.css';
import Logotype from '../../assets/images/logotypes/whitelogo.png';
import UsFlag from './../../assets/svg/Flags/gb';
import SeFlag from './../../assets/svg/Flags/se';
import {NavLink, Link} from 'react-router-dom';
import LanguageContext from '../../context/language-context';

const Navigation = () => {


    return (
        <LanguageContext.Consumer>
            {context => (

                <div className={classes.Navigation}>
                
                <Link to="/"><img style={{transform:'translateY(10px)'}} className={classes.LinkLogotype} src={Logotype} alt=""/></Link>
                <NavLink className={classes.TextLink} to="/services">{context.text.nav.services}</NavLink>
                <NavLink className={classes.TextLink} to="/why">{context.text.nav.why}</NavLink>
                <NavLink className={classes.TextLink} to="/about">{context.text.nav.about}</NavLink>
                <NavLink className={classes.TextLink} to="/contact">{context.text.nav.contact}</NavLink>
                <div className={classes.Flags}>

                    <SeFlag changeLang={() => context.selectLang('se')} />
                    <UsFlag changeLang={() => context.selectLang('en')} />
                </div>

            </div>


            )}



        </LanguageContext.Consumer>
        
    )
}

export default Navigation

