import React from 'react';
import LanguageContext from '../../../../context/language-context';
import classes from './BurgerMenuFooter.module.css';
import UsFlag from './../../../../assets/svg/Flags/gb';
import SeFlag from './../../../../assets/svg/Flags/se';



const BurgerMenuFooter = (props) => {
    return (
        <LanguageContext>
            {context => (
                        <div className={classes.BurgerMenuFooter}>
                            <div className={classes.Flags}>

                                <SeFlag changeLang={() => {
                                    props.closeMenu();
                                    context.selectLang('se')
                                    }} />
                                <UsFlag changeLang={() => {
                                    props.closeMenu();
                                    context.selectLang('en')}} />
                            </div>
                        </div>
            )}

        </LanguageContext>

    )
}

export default BurgerMenuFooter
