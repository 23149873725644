import React, {Component} from 'react';
import classes from './Home.module.css';
import Hero from './Hero/Hero';
import Intro from './Intro/Intro';
import System from './System/System';

class Start extends Component {



    render() {


        return (
        <div className={classes.Home}>
            <Hero />
            <Intro />
            <System />
        </div>   
    
    
        ); 

    }

          
}

 
export default Start;