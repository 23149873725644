import React,{useContext} from 'react';
import clsx from 'clsx';
import classes from './IntroServices.module.css';
import { HashLink as Link } from 'react-router-hash-link';
import LanguageContext from '../../../../context/language-context';


const IntroServices = () => {

 const {isIE} = useContext(LanguageContext);

    return (
    
        <LanguageContext.Consumer>
             {context => (
            <div className={classes.IntroServices}>
                     <div className={classes.IntroServiceExplanation}>
                         <p>{context.text.home.intro.solutions}</p>
                         
                     </div>
                     <Link smooth to='/services#airfreight' className={clsx(classes.IntroServiceBox, classes.IntroServiceAir)}>
                                       
                                 
                                 <p style={isIE?{fontSize:'3.5vw'}:{fontSize:'3vmax'}}>{context.text.home.intro.air}</p> 
     
                    </Link>
                     <Link to='/services#roadfreight' className={clsx(classes.IntroServiceBox, classes.IntroServiceRoad)}>
                         
                     
                         
                         <div className={classes.IntroServiceText}>
                             <p style={isIE?{fontSize:'3.5vw'}:{fontSize:'3vmax'}}>{context.text.home.intro.road}</p> 
                         </div>
                     </Link>
                     <Link to='/services#seafreight' className={clsx(classes.IntroServiceBox, classes.IntroServiceSea)}>
                         
                        
                         <div className={classes.IntroServiceText}>
                             <p style={isIE?{fontSize:'3.5vw'}:{fontSize:'3vmax'}}>{context.text.home.intro.sea}</p> 
                         </div>
                     
                 </Link>
             </div>
             )}
        </LanguageContext.Consumer>


  
               
        
           
           
      

    )
}

export default IntroServices
