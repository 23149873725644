import React from 'react';
import classes from './ProfileSkill.module.css';

const ProfileSkill = (props) => {
    return (


        <div className={classes.ProfileSkills}>
            {props.skills.map(skill => (
                <div key={props.profileIndex+skill} className={classes.ProfileSkillItem}>
                    <div className={classes.CrossWrapper}>
                        <div className={classes.ProfileSkillCross}></div>
                    </div>
                    <p className={classes.ProfileSkillText}>{skill}</p>
   
                </div>
            ))}
        </div>
        
        
    )
}

export default ProfileSkill
